import * as indexDb from 'idb-keyval';

const DB_KEY_PREFIX = 'local_thumbnail_';

export async function cleanThumbnails(filenames: string[]): Promise<void> {
  const existingKeys = filenames.map((filename) => `${DB_KEY_PREFIX}${filename}`);
  const dbKeys = await indexDb.keys();
  const thumbnailKeys = dbKeys.filter((k) => (k as string).includes(DB_KEY_PREFIX));

  const removedKeys = thumbnailKeys.filter((k) => !existingKeys.includes(k as string));

  await indexDb.delMany(removedKeys);
}

export async function getThumbnail(filename: string): Promise<Blob | undefined> {
  const dbKey = `${DB_KEY_PREFIX}${filename}`;
  const dbBlob = await indexDb.get(dbKey);

  return dbBlob;
}

export async function storeThumbnail(filename: string, blob: Blob): Promise<void> {
  const dbKey = `${DB_KEY_PREFIX}${filename}`;
  await indexDb.set(dbKey, blob);
}
