import { File } from '@/interfaces/file.interface';
import { FavoriteState } from '@/interfaces/states/favorite-state.interface';
import { RootState } from '@/interfaces/states/root-state.interface';
import sortFiles from '@/utils/sort-files';

export default {
  items: (state: FavoriteState, _getters: unknown, rootState: RootState): File[] => {
    const { items } = state;

    return sortFiles(items, rootState);
  },

  loading: (state: FavoriteState): boolean => state.loading,

  // eslint-disable-next-line arrow-body-style
  isFavorite: (state: FavoriteState): ((id: string) => boolean) => {
    return (id: string): boolean => state.items.some((item) => item.id === id);
  },
};
