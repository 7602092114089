
import Vue, { PropType } from 'vue';

export default Vue.extend({
  name: 'SyncInfoDialog',
  props: {
    value: Boolean as PropType<boolean>,
  },
  computed: {
    show: {
      set(value: boolean) {
        this.$emit('input', value);
      },
      get(): boolean {
        return this.value;
      },
    },
  },

  methods: {
    confirmDialog(confirm: boolean) {
      this.$emit('confirm', confirm);
    },
  },
});
