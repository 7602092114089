import { ApiFile } from '@/interfaces/api-file.interface';
import { File } from '@/interfaces/file.interface';

export default (items: ApiFile[]): File[] =>
  // eslint-disable-next-line implicit-arrow-linebreak
  items.map((item) => ({
    id: item.id,
    title: item.title,
    categoryIds: item.category_ids,
    location: item.file_location,
    mimeType: item.file_mime_type,
    size: item.file_size,
    isHighlight: item.is_highlight,
    metatags: item.metatags,
    localeIds: item.locale_ids,
    shareable: item.shareable,
    favorite: item.favorite,
    userOnly: item.user_only,
    orientation: item.orientation,
    thumbnailId: item.thumbnail_id,
    created: item.time_created,
    modified: item.time_modified,
    highlightPeriodEnd: item.time_is_highlight_period_end,
    highlightPeriodStart: item.time_is_highlight_period_start,
    publicationPeriodEnd: item.time_publication_period_end,
    publicationPeriodStart: item.time_publication_period_start,
    formFactor: item.published_for_form_factor,
    position: item.position ?? '0',
  }));
