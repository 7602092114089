import { SyncState } from '@/interfaces/states/sync-state.interface';
import { SyncActions } from './actions.enum';

export default {
  [SyncActions.START](state: SyncState): void {
    state.isActive = true;
    state.progress = 0;
    state.errors = [];
  },

  [SyncActions.SET_PROGRESS](state: SyncState, progress: number): void {
    state.progress = progress;
  },

  [SyncActions.CANCEL](state: SyncState): void {
    state.isActive = false;
    state.progress = 0;
  },

  [SyncActions.FINISH](state: SyncState): void {
    state.isActive = false;
    state.progress = 0;
  },

  [SyncActions.ADD_ERROR](state: SyncState, error: Error): void {
    state.errors = [...state.errors, error];

    // rethrow so it gets logged in sentry
    throw error;
  },

  [SyncActions.RESET_ERRORS](state: SyncState): void {
    state.errors = [];
  },
};
