import { Category } from '@/interfaces/category.interface';
import { Sorting } from '@/interfaces/sorting.interface';

function getPosition(sortings: Sorting[], item: Category) {
  return sortings.filter(({ categoryId }) => item.id === categoryId?.toString()) ?? [];
}

export default (items: Category[], sortings: Sorting[]): Category[] =>
  // eslint-disable-next-line implicit-arrow-linebreak
  items.map((item) => ({
    ...item,
    positions: getPosition(sortings, item),
  }));
