import { SearchState } from '@/interfaces/states/search-state.interface';

const state: SearchState = {
  items: [],
  loading: false,
  keyword: '',
  error: {
    code: null,
    msg: null,
  },
};

export default state;
