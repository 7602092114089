/* eslint-disable camelcase */

import { ActionContext } from 'vuex';
import { ResponseError } from '@/classes/response-error';
import { ListFavoriteFilesReponse } from '@/dtos/list-favorite-files-response.interface';
import i18n from '@/i18n';
import { ApiFavoriteFileEntry } from '@/interfaces/api-favorite-file-entry.interface';
import { File } from '@/interfaces/file.interface';
import { SetStateError } from '@/interfaces/set-state-error.interface';
import { FavoriteState } from '@/interfaces/states/favorite-state.interface';
import { RootState } from '@/interfaces/states/root-state.interface';
import { NotificationActions } from '@/store/notification/actions.enum';
import fetchWithToken from '@/utils/fetch-with-token';
import getResJson from '@/utils/get-res-json';
import mapThumbnailToFiles from '@/utils/map-thumbnail-files';
import apiFileToFile from '@/utils/transform/api-file-to-file';
import apiThumbnailToThumbnail from '@/utils/transform/api-thumbnail-to-thumbnail';
import { FavoriteActions } from './actions.enum';

const BASE_URL = `${process.env.VUE_APP_API_URL}/favorite/files`;

export default {
  async [FavoriteActions.GET_ALL]({
    commit,
    dispatch,
    rootState,
  }: ActionContext<FavoriteState, RootState>): Promise<void> {
    const url = `${BASE_URL}/list/${rootState.locale.currentId}/0`;

    try {
      await dispatch(FavoriteActions.SET_LOADING, true);
      const response = await fetchWithToken(rootState, url, dispatch);

      const data = await getResJson<ListFavoriteFilesReponse>(response);

      const items = mapThumbnailToFiles(
        apiFileToFile(data.items),
        apiThumbnailToThumbnail(data.thumbnails),
      );

      commit(FavoriteActions.GET_ALL, items);
      await dispatch(FavoriteActions.SET_LOADING, false);
    } catch (error) {
      dispatch(FavoriteActions.SET_ERROR, { error, i18nKey: 'favorite.errGetAll' });

      throw error;
    }
  },

  async [FavoriteActions.CREATE](
    { commit, dispatch, rootState }: ActionContext<FavoriteState, RootState>,
    item: File,
  ): Promise<void> {
    const url = `${BASE_URL}`;
    const body = JSON.stringify({
      folder_id: 0,
      file_id: +item.id,
    } as ApiFavoriteFileEntry);

    try {
      const response = await fetchWithToken(rootState, url, dispatch, {
        method: 'POST',
        headers: {
          'content-type': 'application/json',
          accept: 'application/json',
        },
        body,
      });

      if (!response.ok) {
        throw new ResponseError(response);
      }

      commit(FavoriteActions.CREATE, item);
    } catch (error) {
      dispatch(FavoriteActions.SET_ERROR, { error, i18nKey: 'favorite.errCreate' });

      throw error;
    }
  },

  async [FavoriteActions.DELETE](
    { commit, dispatch, rootState }: ActionContext<FavoriteState, RootState>,
    id: string,
  ): Promise<void> {
    const url = `${BASE_URL}?folder_id=0&file_id=${id}`;

    try {
      const response = await fetchWithToken(rootState, url, dispatch, {
        method: 'DELETE',
      });

      if (!response.ok) {
        throw new ResponseError(response);
      }

      commit(FavoriteActions.DELETE, id);
    } catch (error) {
      dispatch(FavoriteActions.SET_ERROR, { error, i18nKey: 'favorite.errDelete' });

      throw error;
    }
  },

  [FavoriteActions.SET_LOADING](
    { commit }: ActionContext<FavoriteState, RootState>,
    loading: boolean,
  ): void {
    commit(FavoriteActions.SET_LOADING, loading);
  },

  [FavoriteActions.SET_ERROR](
    { commit, dispatch }: ActionContext<FavoriteState, RootState>,
    { error, i18nKey }: SetStateError,
  ): void {
    commit(FavoriteActions.SET_ERROR, error);
    dispatch(`notification/${NotificationActions.SET_ERROR}`, i18n.t(i18nKey).toString(), {
      root: true,
    });
  },
};
