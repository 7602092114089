
import SyncErrorDialog from '@/components/SyncErrorDialog.vue';
import SyncInfoDialog from '@/components/SyncInfoDialog.vue';
import SyncStopDialog from '@/components/SyncStopDialog.vue';
import { Locale } from '@/interfaces/locale.interface';
import { MenuItem } from '@/interfaces/menu-item.interface';
import OnlineMixin from '@/mixins/online';
import { AuthActions } from '@/store/auth/actions.enum';
import { LocaleActions } from '@/store/locale/actions.enum';
import { NotificationActions } from '@/store/notification/actions.enum';
import { SyncActions } from '@/store/sync/actions.enum';
// eslint-disable-next-line import/order
import Vue from 'vue';
// eslint-disable-next-line import/order
import { mapActions, mapGetters } from 'vuex';

export default Vue.extend({
  name: 'TheMenu',

  mixins: [OnlineMixin],

  components: {
    SyncErrorDialog,
    SyncStopDialog,
    SyncInfoDialog,
  },

  data() {
    return {
      syncErrDialogVisible: false,
      syncInfoDialogVisible: false,
      syncStopDialogVisible: false,
      previousLocaleId: null as null | string,
      newLocaleId: null as null | string,
      renderLocaleSelect: true,
    };
  },

  watch: {
    isOnline(newValue) {
      if (!newValue && this.syncActive) {
        this.cancelSyncWithMsg('sync.errOffline');
      }
    },
  },

  computed: {
    ...mapGetters('locale', { locales: 'items', currentId: 'currentId' }),
    ...mapGetters('sync', {
      syncActive: 'isActive',
      syncProgress: 'progress',
      syncErrors: 'errors',
    }),
    ...mapGetters('file', ['showHighlights']),

    currentLocale: {
      get(): Locale {
        return this.locales.find(({ id }: Locale) => this.currentId === id);
      },

      set(localeId: string) {
        if (localeId === this.currentId) {
          return;
        }

        this.previousLocaleId = this.currentId;
        this.newLocaleId = localeId;

        if (this.syncActive) {
          this.syncStopDialogVisible = true;
        } else {
          this.setCurrentLocale(localeId);
        }

        this.renderLocaleSelect = false;
        this.$nextTick(() => {
          this.renderLocaleSelect = true;
        });
      },
    },

    items(): MenuItem[] {
      const highlightItem: MenuItem = {
        title: this.$t('menu.btnHighlights'),
        icon: 'mdi-star-outline',
        activeIcon: 'mdi-star',
        link: '/highlights',
      };

      const localFileItem: MenuItem = {
        title: this.$t('menu.btnLocalFiles'),
        icon: 'mdi-folder-outline',
        activeIcon: 'mdi-folder',
        link: '/local-files',
      };

      let items: MenuItem[] = [
        {
          title: this.$t('menu.btnLastUploaded'),
          icon: 'mdi-file-upload-outline',
          activeIcon: 'mdi-file-upload',
          link: '/last-uploaded',
        },
        {
          title: this.$t('menu.btnCategories'),
          icon: 'mdi-inbox-full-outline',
          activeIcon: 'mdi-inbox-full',
          link: '/categories',
        },
        {
          title: this.$t('menu.btnFavorites'),
          icon: 'mdi-heart-outline',
          activeIcon: 'mdi-heart',
          link: '/favorites',
        },
        {
          title: this.$t('menu.btnSearch'),
          icon: 'mdi-magnify',
          activeIcon: 'mdi-magnify',
          link: '/search',
        },
      ];

      if (this.showHighlights) {
        items = [highlightItem, ...items];
      }

      if (process.env.VUE_APP_SHOW_LOCAL_FILES === 'true') {
        items = [...items, localFileItem];
      }

      return items;
    },

    logoPath() {
      return `${process.env.BASE_URL}img/logos/login.svg`;
    },
  },

  methods: {
    ...mapActions('auth', { performLogout: AuthActions.LOGOUT }),
    ...mapActions('locale', { setCurrentLocale: LocaleActions.SET_CURRENT }),
    ...mapActions('sync', {
      startSync: SyncActions.START,
      cancelSync: SyncActions.CANCEL,
      resetSyncErrors: SyncActions.RESET_ERRORS,
    }),
    ...mapActions('notification', { setError: NotificationActions.SET_ERROR }),

    cancelSyncWithMsg(i18nKey: string) {
      this.cancelSync();
      this.setError(this.$t(i18nKey));

      // need to wait because of delay in request cancellation
      setTimeout(() => {
        this.resetSyncErrors();
      }, 300);
    },

    showSyncInfoDialog() {
      this.syncInfoDialogVisible = true;
    },

    showSyncErrDialog() {
      if (this.syncErrors.length > 0) {
        this.syncErrDialogVisible = true;
      }
    },

    async syncInfoDialogConfirmed(confirmed: boolean) {
      this.syncInfoDialogVisible = false;

      if (confirmed) {
        await this.startSync();
      }
    },

    async syncStopDialogConfirmed(confirmed: boolean) {
      this.syncStopDialogVisible = false;

      const localeId = confirmed ? this.newLocaleId : this.previousLocaleId;

      if (confirmed) {
        await this.cancelSync();
      }

      this.setCurrentLocale(localeId);
    },

    async logout() {
      await this.performLogout();
      this.$router.push('/');
    },
  },
});
