import { SortType } from '@/enums/sort-type.enum';
import { File } from '@/interfaces/file.interface';
import { RootState } from '@/interfaces/states/root-state.interface';
import alphaSort from '@/utils/alphabetic-sort';
import isAsc from '@/utils/is-asc-sort';
import stringToDate from '@/utils/transform/string-to-date';

export default (files: File[], rootState: RootState): File[] => {
  const sorted = files.sort(
    (
      { title, position, modified },
      { title: title2, position: position2, modified: modified2 },
    ) => {
      const titleSort = alphaSort(title, title2);

      switch (rootState.sort.type) {
        case SortType.CMS_ASC:
        case SortType.CMS_DESC:
          // eslint-disable-next-line no-case-declarations
          const positionSort = position && position2 ? alphaSort(position, position2) : null;

          return positionSort || titleSort;
        case SortType.TITLE_ASC:
        case SortType.TITLE_DESC:
          return titleSort;
        case SortType.DATE_ASC:
        case SortType.DATE_DESC:
          return stringToDate(modified2).getTime() - stringToDate(modified).getTime();
          break;
        default:
          return titleSort;
      }
    },
  );

  return isAsc(rootState.sort.type) ? sorted : sorted.reverse();
};
