import Vue from 'vue';

export default Vue.extend({
  data() {
    return {
      refreshing: false,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      registration: null as ServiceWorkerRegistration | null,
      updateExists: false,
    };
  },

  created() {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    document.addEventListener(('swUpdated' as unknown) as any, this.updateAvailable, {
      once: true,
    });

    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.addEventListener('controllerchange', this.refreshPage);
    }
  },

  methods: {
    updateAvailable(event: { detail: ServiceWorkerRegistration }) {
      this.registration = event.detail;
      this.updateExists = true;

      if (this.registration?.waiting) {
        this.registration.waiting.postMessage({ type: 'UPDATE_AVAILABLE' });
      }
    },

    refreshApp() {
      this.updateExists = false;

      if (this.registration?.waiting) {
        this.registration.waiting.postMessage({ type: 'SKIP_WAITING' });
      }
    },

    refreshPage() {
      if (this.refreshing) return;
      this.refreshing = true;
      window.location.reload();
    },
  },
});
