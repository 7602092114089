import { SortType } from '@/enums/sort-type.enum';
import { RootState } from '@/interfaces/states/root-state.interface';
import { SortState } from '@/interfaces/states/sort-state.interface';
// eslint-disable-next-line import/order
import { ActionContext } from 'vuex';
import { SortActions } from './actions.enum';

export default {
  [SortActions.SET_TYPE]({ commit }: ActionContext<SortState, RootState>, type: SortType): void {
    commit(SortActions.SET_TYPE, type);
  },
};
