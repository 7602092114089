import { AuthState } from '@/interfaces/states/auth-state.interface';

const state: AuthState = {
  token: null,
  username: null,
  password: null,
  userId: null,
  error: {
    code: null,
    msg: null,
  },
};

export default state;
