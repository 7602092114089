import { NotificationState } from '@/interfaces/states/notification-state.interface';
import { NotificationActions } from './actions.enum';

export default {
  [NotificationActions.SET_ERROR](state: NotificationState, error: string): void {
    state.error = error;
  },

  [NotificationActions.SET_SUCCESS](state: NotificationState, success: string): void {
    state.success = success;
  },
};
