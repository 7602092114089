import { File } from '@/interfaces/file.interface';
import { SearchState } from '@/interfaces/states/search-state.interface';
import { SearchActions } from './actions.enum';

export default {
  [SearchActions.SEARCH](
    state: SearchState,
    { items, keyword }: { items: File[]; keyword: string },
  ): void {
    state.items = items;
    state.keyword = keyword;
    state.error = { code: null, msg: null };
  },

  [SearchActions.RESET](state: SearchState): void {
    state.items = [];
    state.keyword = '';
    state.error = { code: null, msg: null };
  },

  [SearchActions.SET_LOADING](state: SearchState, loading: boolean): void {
    state.loading = loading;
  },

  [SearchActions.SET_ERROR](state: SearchState, error: Error & { response: Response }): void {
    state.error = {
      code: error?.response?.status.toString() ?? 0,
      msg: error.response?.statusText ?? error.message,
    };
    console.error(error);
  },
};
