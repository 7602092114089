// eslint-disable-next-line import/prefer-default-export
export class ResponseError extends Error {
  response: Response;

  constructor(response: Response) {
    super();

    this.response = response;
    this.message = `[${this.response.status}] ${this.response.statusText} <${this.response.url}>`;
  }
}
