import { isValid, parse } from 'date-fns';

export default (dateString: string): Date => {
  const dateFormats = ['yyyy-MM-dd HH:mm:ss', "yyyy-MM-dd'T'HH:mm:ss.SSSX"];
  const referenceDate = new Date(Date.now());

  let parsedDate: Date | null = null;

  // eslint-disable-next-line no-restricted-syntax
  for (const dateFormat of dateFormats) {
    parsedDate = parse(dateString, dateFormat, referenceDate);

    if (isValid(parsedDate)) {
      break;
    }
  }

  if (!parsedDate) {
    throw new Error(`Invalid date dateString: ${dateString}`);
  }

  return parsedDate;
};
