import { File } from '@/interfaces/file.interface';
import { FileState } from '@/interfaces/states/file-state.interface';
import { RootState } from '@/interfaces/states/root-state.interface';
import sortFiles from '@/utils/sort-files';

export default {
  highlights: (state: FileState, _getters: unknown, rootState: RootState): File[] => {
    const { highlights } = state;

    return sortFiles(highlights, rootState);
  },
  lastUploaded: (state: FileState): File[] => state.lastUploaded,
  loading: (state: FileState): boolean => state.loading,
  showHighlights: (state: FileState): boolean => state.showHighlights,
};
