import { StateError } from '@/interfaces/state-error.interface';
import { AuthState } from '@/interfaces/states/auth-state.interface';

export default {
  token: (state: AuthState): string | null => state.token,
  username: (state: AuthState): string | null => state.username,
  password: (state: AuthState): string | null => state.password,
  userId: (state: AuthState): string | null => state.userId,
  error: (state: AuthState): StateError => state.error,
  errorCode: (state: AuthState): string | null => state.error.code,
  errorMsg: (state: AuthState): string | null => state.error.msg,
};
