import { FavoriteState } from '@/interfaces/states/favorite-state.interface';

const state: FavoriteState = {
  items: [],
  loading: false,
  error: {
    code: null,
    msg: null,
  },
};

export default state;
