// eslint-disable-next-line import/no-cycle
import { Dispatch } from 'vuex';
import { ResponseError } from '@/classes/response-error';
import { LoginResponse } from '@/dtos/login-response.interface';
import { RootState } from '@/interfaces/states/root-state.interface';
import { AuthActions } from '@/store/auth/actions.enum';

async function refreshToken(
  rootState: RootState,
  input: RequestInfo,
  dispatch?: Dispatch | null,
  init?: RequestInit | undefined,
): Promise<Response> {
  let { token } = rootState.auth;

  const payload = {
    username: rootState.auth.username,
    password: rootState.auth.password,
  };
  if (dispatch) {
    await dispatch(`auth/${AuthActions.LOGIN}`, payload, { root: true });
  } else {
    const url = `${process.env.VUE_APP_API_URL}/auth/loginv2`;

    const { username, password } = payload;
    const response = await fetch(url, {
      headers: {
        authorization: `Basic ${btoa(unescape(encodeURIComponent(`${username}:${password}`)))}`,
        'X-Uid': '1234',
        'app-client': 'pwa',
      },
    });

    if (!response.ok) {
      throw new ResponseError(response);
    }

    const res = (await response.json()) as LoginResponse;
    token = res.token;
  }

  let refreshedInit = {};

  if (init) {
    refreshedInit = {
      ...init,
      headers: {
        ...init.headers,
        authorization: `Bearer ${token}`,
      },
    };
  }

  return fetch(input, refreshedInit);
}

export default async (
  rootState: RootState,
  input: RequestInfo,
  dispatch?: Dispatch | null,
  init?: RequestInit | undefined,
): Promise<Response> => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const reqInit: any = {
    ...init,
    headers: {
      ...init?.headers,
      authorization: `Bearer ${rootState.auth.token}`,
    },
  };

  const result = fetch(input, reqInit);
  const response = await result;

  if ([401, 403].includes(response.status)) {
    return refreshToken(rootState, input, dispatch, reqInit);
  }

  return response;
};
