import Vue from 'vue';

export default Vue.extend({
  data() {
    return { isOnline: true };
  },

  created() {
    window.addEventListener('online', this.onlineListener);
    window.addEventListener('offline', this.offlineListener);
  },

  destroyed() {
    window.removeEventListener('online', this.onlineListener);
    window.removeEventListener('offline', this.offlineListener);
  },

  methods: {
    onlineListener() {
      this.isOnline = true;
    },

    offlineListener() {
      this.isOnline = false;
    },
  },
});
