import { File } from '@/interfaces/file.interface';
import { RootState } from '@/interfaces/states/root-state.interface';
import { SearchState } from '@/interfaces/states/search-state.interface';
import sortFiles from '@/utils/sort-files';

export default {
  items: (state: SearchState, _getters: unknown, rootState: RootState): File[] => {
    const { items } = state;

    return sortFiles(items, rootState);
  },

  loading: (state: SearchState): boolean => state.loading,

  keyword: (state: SearchState): string => state.keyword,
};
