import { ApiSorting } from '@/interfaces/api-sorting.interface';
import { Sorting } from '@/interfaces/sorting.interface';

export default (items: ApiSorting[]): Sorting[] =>
  // eslint-disable-next-line implicit-arrow-linebreak
  items.map((item) => ({
    id: item.id,
    localeId: item.locale_id,
    categoryId: item.category_id,
    parentCategoryId: item.parent_category_id,
    position: item.position,
    created: item.time_created,
    modified: item.time_modified,
  }));
