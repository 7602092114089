import { File } from '@/interfaces/file.interface';
import { FavoriteState } from '@/interfaces/states/favorite-state.interface';
import { FavoriteActions } from './actions.enum';

export default {
  [FavoriteActions.GET_ALL](state: FavoriteState, items: File[]): void {
    state.items = items;
    state.error = { code: null, msg: null };
  },

  [FavoriteActions.CREATE](state: FavoriteState, item: File): void {
    state.items = [...state.items, item];
    state.error = { code: null, msg: null };
  },

  [FavoriteActions.DELETE](state: FavoriteState, deleteId: string): void {
    state.items = state.items.filter(({ id }) => id !== deleteId);
    state.error = { code: null, msg: null };
  },

  [FavoriteActions.SET_LOADING](state: FavoriteState, loading: boolean): void {
    state.loading = loading;
  },

  [FavoriteActions.SET_ERROR](state: FavoriteState, error: Error & { response: Response }): void {
    state.error = {
      code: error?.response?.status.toString() ?? 0,
      msg: error.response?.statusText ?? error.message,
    };
    console.error(error);
  },
};
