/* eslint-disable import/no-webpack-loader-syntax */
/* eslint-disable import/no-unresolved */

import { ActionContext } from 'vuex';
import SyncWorker from 'worker-loader!@/workers/sync.worker';
import { SyncEvents } from '@/enums/sync-events.enum';
import { RootState } from '@/interfaces/states/root-state.interface';
import { SyncState } from '@/interfaces/states/sync-state.interface';
import { SyncActions } from './actions.enum';

// exported to allow unit tests
// only solution I have found to make it work
export const worker = new SyncWorker() as Worker;

export default {
  async [SyncActions.START]({
    commit,
    state,
    rootState,
    dispatch,
  }: ActionContext<SyncState, RootState>): Promise<void> {
    if (state.isActive) {
      return;
    }

    commit(SyncActions.START);

    worker.postMessage({
      eventLabel: SyncEvents.START,
      rootState,
    });

    worker.onmessage = ({ data }: MessageEvent) => {
      const { eventLabel, value } = data;

      if (eventLabel === SyncEvents.PROGRESS) {
        commit(SyncActions.SET_PROGRESS, value);
      } else if (eventLabel === SyncEvents.FINISH) {
        dispatch(SyncActions.FINISH);
      } else if (eventLabel === SyncEvents.ERROR) {
        dispatch(SyncActions.ADD_ERROR, value);
      }
    };
  },

  [SyncActions.SET_PROGRESS](
    { commit }: ActionContext<SyncState, RootState>,
    progress: number,
  ): void {
    commit(SyncActions.SET_PROGRESS, progress);
  },

  [SyncActions.CANCEL]({ commit }: ActionContext<SyncState, RootState>): void {
    commit(SyncActions.CANCEL);

    worker.postMessage({
      eventLabel: SyncEvents.CANCEL,
    });
  },

  [SyncActions.FINISH]({ commit }: ActionContext<SyncState, RootState>): void {
    commit(SyncActions.FINISH);
  },

  [SyncActions.ADD_ERROR]({ commit }: ActionContext<SyncState, RootState>, error: Error): void {
    commit(SyncActions.ADD_ERROR, error);
  },

  [SyncActions.RESET_ERRORS]({ commit }: ActionContext<SyncState, RootState>): void {
    commit(SyncActions.RESET_ERRORS);
  },
};
