import Vue from 'vue';
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify);

const mq = window.matchMedia('(prefers-color-scheme: dark)');

const opts = {
  theme: {
    dark: mq.matches,
  },
};

const vuetify = new Vuetify(opts);

mq.addEventListener('change', (e) => {
  vuetify.framework.theme.dark = e.matches;
});

export default vuetify;
