import { ApiLocale } from '@/interfaces/api-locale.interface';
import { Locale } from '@/interfaces/locale.interface';

export default (items: ApiLocale[]): Locale[] =>
  // eslint-disable-next-line implicit-arrow-linebreak
  items.map((item) => ({
    id: item.id,
    title: item.title,
    iso2: item.iso2,
    iso5: item.iso5,
    isoFull: item.iso_full,
    created: item.time_created,
    modified: item.time_modified,
  }));
