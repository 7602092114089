import { ActionContext } from 'vuex';
import { CategoryItemsResponse } from '@/dtos/category-items-response.interface';
import { CategoryListResponse } from '@/dtos/category-list-response.interface';
import i18n from '@/i18n';
import { SetStateError } from '@/interfaces/set-state-error.interface';
import { CategoryState } from '@/interfaces/states/category-state.interface';
import { RootState } from '@/interfaces/states/root-state.interface';
import { NotificationActions } from '@/store/notification/actions.enum';
import fetchWithToken from '@/utils/fetch-with-token';
import getResJson from '@/utils/get-res-json';
import mapSortingsToCategory from '@/utils/map-sorting-category';
import mapThumbnailToFiles from '@/utils/map-thumbnail-files';
import apiCategoryToCategory from '@/utils/transform/api-category-to-category';
import apiFileToFile from '@/utils/transform/api-file-to-file';
import apiSortingToSorting from '@/utils/transform/api-sorting-to-sorting';
import apiThumbnailToThumbnail from '@/utils/transform/api-thumbnail-to-thumbnail';
import { CategoryActions } from './actions.enum';

export default {
  async [CategoryActions.FETCH]({
    commit,
    dispatch,
    rootState,
  }: ActionContext<CategoryState, RootState>): Promise<void> {
    const url = `${process.env.VUE_APP_API_URL}/category/list/${rootState.locale.currentId}`;

    try {
      const fetchCategoryList = async () => {
        const response = await fetchWithToken(rootState, url, dispatch);

        const data = await getResJson<CategoryListResponse>(response);

        commit(
          CategoryActions.FETCH,
          mapSortingsToCategory(
            apiCategoryToCategory(data.items),
            apiSortingToSorting(data.sortings),
          ),
        );
      };

      await fetchCategoryList();

      // to fetch current list due to stale workbox strategy
      setTimeout(() => fetchCategoryList(), 500);
    } catch (error) {
      dispatch(CategoryActions.SET_ERROR, { error, i18nKey: 'category.errFetch' });

      throw error;
    }
  },

  async [CategoryActions.FETCH_ITEMS](
    { commit, dispatch, rootState }: ActionContext<CategoryState, RootState>,
    id: string,
  ): Promise<void> {
    const url = `${process.env.VUE_APP_API_URL}/category/items/${id}`;

    try {
      const fetchCategoryItems = async () => {
        const response = await fetchWithToken(rootState, url, dispatch);

        const data = await getResJson<CategoryItemsResponse>(response);

        const items = mapThumbnailToFiles(
          apiFileToFile(data.items),
          apiThumbnailToThumbnail(data.thumbnails),
        );

        commit(CategoryActions.FETCH_ITEMS, items);
      };

      await dispatch(CategoryActions.SET_LOADING, true);
      await fetchCategoryItems();
      await dispatch(CategoryActions.SET_LOADING, false);

      // to fetch current list due to stale workbox strategy
      setTimeout(() => fetchCategoryItems(), 500);
    } catch (error) {
      dispatch(CategoryActions.SET_ERROR, { error, i18nKey: 'category.errFetchItems' });

      throw error;
    }
  },

  [CategoryActions.CLEAR_CATEGORY_FILES]({
    commit,
  }: ActionContext<CategoryState, RootState>): void {
    commit(CategoryActions.CLEAR_CATEGORY_FILES);
  },

  [CategoryActions.SET_LOADING](
    { commit }: ActionContext<CategoryState, RootState>,
    loading: boolean,
  ): void {
    commit(CategoryActions.SET_LOADING, loading);
  },

  [CategoryActions.SET_LEVEL](
    { commit }: ActionContext<CategoryState, RootState>,
    level: number,
  ): void {
    commit(CategoryActions.SET_LEVEL, level);
  },

  [CategoryActions.SET_ERROR](
    { commit, dispatch }: ActionContext<CategoryState, RootState>,
    { error, i18nKey }: SetStateError,
  ): void {
    commit(CategoryActions.SET_ERROR, error);
    dispatch(`notification/${NotificationActions.SET_ERROR}`, i18n.t(i18nKey).toString(), {
      root: true,
    });
  },
};
