/* eslint-disable no-shadow */
// eslint-disable-next-line import/prefer-default-export
export enum CategoryActions {
  FETCH = '[Category] Fetch',
  FETCH_ITEMS = '[Category] Fetch Items',
  CLEAR_CATEGORY_FILES = '[Category] Clear category files',
  SET_LOADING = '[Category] Set Loading',
  SET_LEVEL = '[Category] Set Level',
  SET_ERROR = '[Category] Set Error',
}
