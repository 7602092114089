const cache = new Map<string, string>();

export default async (ext: string): Promise<string> => {
  if (cache.has(ext)) {
    return cache.get(ext) as string;
  }

  const canvas = document.createElement('canvas');

  // two times for retina displays
  canvas.width = 250 * 2;
  canvas.height = 128 * 2;
  const ctx = canvas.getContext('2d');

  if (!ctx) {
    return '';
  }

  // center of thumbnail img
  const x = canvas.width / 2;
  const y = canvas.height / 2;

  // draw boarder
  const radius = 8 * 2;
  const width = 80 * 2;
  const height = 100 * 2;

  // two times for retina displays
  const borderX = x - width / 2;
  const borderY = y - height / 2;

  const startX = borderX + radius;
  const endX = borderX + width;
  const endY = borderY + height;

  ctx.fillStyle = '#000';
  ctx.shadowBlur = 2;
  ctx.shadowOffsetX = 1;
  ctx.shadowOffsetY = 1;
  ctx.shadowColor = '#000';

  ctx.fillStyle = '#fff';
  ctx.lineWidth = 1;

  ctx.beginPath();
  ctx.moveTo(startX, borderY);
  ctx.lineTo(endX - radius * 1.5, borderY);
  ctx.lineTo(endX, borderY + radius * 1.5);
  ctx.lineTo(endX, endY - radius);
  ctx.quadraticCurveTo(endX, endY, endX - radius, endY);
  ctx.lineTo(startX, endY);
  ctx.quadraticCurveTo(borderX, endY, borderX, endY - radius);
  ctx.lineTo(borderX, borderY + radius);
  ctx.quadraticCurveTo(borderX, borderY, startX, borderY);
  ctx.closePath();

  ctx.fill();

  ctx.shadowBlur = 0;
  ctx.shadowOffsetX = 0;
  ctx.shadowOffsetY = 0;
  ctx.strokeStyle = '#D2D5DA';

  ctx.stroke();

  // draw text
  ctx.fillStyle = '#000';
  ctx.font = 'bold 40px Roboto';
  ctx.textAlign = 'center';
  ctx.textBaseline = 'middle';

  ctx.fillText(ext.toUpperCase(), x, y);

  const location = await new Promise<string>((resolve) => {
    canvas.toBlob((blob) => {
      if (blob) {
        const url = URL.createObjectURL(blob);
        cache.set(ext, url);

        return resolve(url);
      }

      return resolve('');
    });
  });

  return location;
};

export const clearMimeThumbnailCache = (): void => cache.clear();
