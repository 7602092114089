import { Locale } from '@/interfaces/locale.interface';
import { StateError } from '@/interfaces/state-error.interface';
import { LocaleState } from '@/interfaces/states/locale-state.interface';
import alphaSort from '@/utils/alphabetic-sort';

export default {
  items: (state: LocaleState): Locale[] => {
    const { items } = state;
    return items?.sort(({ title }, { title: title2 }) => alphaSort(title, title2)) ?? [];
  },

  currentId: (state: LocaleState): string | null => state.currentId,

  error: (state: LocaleState): StateError => state.error,

  errorCode: (state: LocaleState): string | null => state.error.code,

  errorMsg: (state: LocaleState): string | null => state.error.msg,
};
