import { LocalFileState } from '@/interfaces/states/local-file-state.interface';

const state: LocalFileState = {
  items: [],
  currentUrl: null,
  loading: true,
  error: {
    code: null,
    msg: null,
  },
};

export default state;
