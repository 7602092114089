import { File } from '@/interfaces/file.interface';
import { StateError } from '@/interfaces/state-error.interface';
import { LocalFileState } from '@/interfaces/states/local-file-state.interface';
import { RootState } from '@/interfaces/states/root-state.interface';
import sortFiles from '@/utils/sort-files';

export default {
  items: (state: LocalFileState, _getters: unknown, rootState: RootState): File[] => {
    const { items } = state;

    return sortFiles(items, rootState);
  },
  loading: (state: LocalFileState): boolean => state.loading,
  error: (state: LocalFileState): StateError => state.error,
};
