import { ApiCategory } from '@/interfaces/api-category.interface';
import { Category } from '@/interfaces/category.interface';

export default (items: ApiCategory[]): Category[] =>
  // eslint-disable-next-line implicit-arrow-linebreak
  items.map((item) => ({
    id: item.id,
    title: item.title,
    parentIds: item.parent_ids,
    childIds: item.child_ids,
    localeIds: item.locale_ids,
    created: item.time_created,
    modified: item.time_modified,
    positions: item.position ?? [],
  }));
