/* eslint-disable no-shadow */
// eslint-disable-next-line import/prefer-default-export
export enum SyncActions {
  START = '[Sync] Start',
  SET_PROGRESS = '[Sync] Set Progress',
  CANCEL = '[Sync] Cancel',
  FINISH = '[Sync] Finish',
  ADD_ERROR = '[Sync] Add Error',
  RESET_ERRORS = '[Sync] Reset Errors',
}
