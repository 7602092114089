import '@mdi/font/css/materialdesignicons.min.css';
import PortalVue from 'portal-vue';
import Vue from 'vue';
import App from './App.vue';
import './assets/styles/fonts.css';
import './assets/styles/index.css';
import i18n from './i18n';
import vuetify from './plugins/vuetify';
import './registerServiceWorker';
import router from './router';
import store from './store';

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: (h) => h(App),
}).$mount('#app');

Vue.use(PortalVue);
