import { ApiThumbnail } from '@/interfaces/api-thumbnail.interface';
import { Thumbnail } from '@/interfaces/thumbnail.interface';

export default (items: ApiThumbnail[]): Thumbnail[] =>
  // eslint-disable-next-line implicit-arrow-linebreak
  items.map((item) => ({
    id: item.id,
    location: item.file_location,
    modified: item.file_modified,
    size: item.size,
  }));
