import { LocaleListResponse } from '@/dtos/locale-list-response.interface';
import i18n from '@/i18n';
import { SetStateError } from '@/interfaces/set-state-error.interface';
import { LocaleState } from '@/interfaces/states/locale-state.interface';
import { RootState } from '@/interfaces/states/root-state.interface';
import { CategoryActions } from '@/store/category/actions.enum';
import { FavoriteActions } from '@/store/favorite/actions.enum';
import { FileActions } from '@/store/file/actions.enum';
import { NotificationActions } from '@/store/notification/actions.enum';
import { SearchActions } from '@/store/search/actions.enum';
import fetchWithToken from '@/utils/fetch-with-token';
import getResJson from '@/utils/get-res-json';
import apiLocaleToLocale from '@/utils/transform/api-locale-to-locale';
// eslint-disable-next-line import/order
import { ActionContext } from 'vuex';
import { LocaleActions } from './actions.enum';

export default {
  async [LocaleActions.FETCH]({
    commit,
    dispatch,
    rootState,
  }: ActionContext<LocaleState, RootState>): Promise<void> {
    const url = `${process.env.VUE_APP_API_URL}/locale/list`;

    try {
      const response = await fetchWithToken(rootState, url, dispatch);

      const data = await getResJson<LocaleListResponse>(response);

      commit(LocaleActions.FETCH, apiLocaleToLocale(data.items));
    } catch (error) {
      dispatch(LocaleActions.SET_ERROR, { error, i18nKey: 'locale.errFetch' });

      throw error;
    }
  },

  async [LocaleActions.SET_CURRENT](
    // eslint-disable-next-line object-curly-newline
    { commit, dispatch, state, rootState }: ActionContext<LocaleState, RootState>,
    payload: string,
  ): Promise<void> {
    // eslint-disable-next-line operator-linebreak
    const reloadNotNeccessary =
      // eslint-disable-next-line operator-linebreak
      state.currentId === payload &&
      (
        rootState.file.highlights.length > 0 ||
        rootState.file.lastUploaded.length > 0 ||
        rootState.category.items.length > 0
      );

    if (reloadNotNeccessary) {
      return;
    }

    commit(LocaleActions.SET_CURRENT, payload);

    await Promise.all([
      dispatch(`file/${FileActions.FETCH_HIGHLIGHTS}`, null, { root: true }),
      dispatch(`file/${FileActions.FETCH_LAST_UPLOADED}`, null, { root: true }),
      dispatch(`category/${CategoryActions.FETCH}`, null, { root: true }),
      dispatch(`favorite/${FavoriteActions.GET_ALL}`, null, { root: true }),
      dispatch(`search/${SearchActions.RESET}`, null, { root: true }),
    ]);
  },

  [LocaleActions.SET_ERROR](
    { commit, dispatch }: ActionContext<LocaleState, RootState>,
    { error, i18nKey }: SetStateError,
  ): void {
    commit(LocaleActions.SET_ERROR, error);
    dispatch(`notification/${NotificationActions.SET_ERROR}`, i18n.t(i18nKey).toString(), {
      root: true,
    });
  },
};
