import { ResponseError } from '@/classes/response-error';

export default async <T>(res: Response): Promise<T> => {
  if (!res.ok) {
    throw new ResponseError(res);
  }

  const data = (await res.json()) as T;

  return data;
};
