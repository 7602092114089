import { ActionContext } from 'vuex';
import { NotificationState } from '@/interfaces/states/notification-state.interface';
import { RootState } from '@/interfaces/states/root-state.interface';
import { NotificationActions } from './actions.enum';

export default {
  [NotificationActions.SET_ERROR](
    { commit }: ActionContext<NotificationState, RootState>,
    error: string,
  ): void {
    commit(NotificationActions.SET_ERROR, error);
  },

  [NotificationActions.SET_SUCCESS](
    { commit }: ActionContext<NotificationState, RootState>,
    success: string,
  ): void {
    commit(NotificationActions.SET_SUCCESS, success);
  },
};
