/* eslint-disable no-shadow */
// eslint-disable-next-line import/prefer-default-export
export enum SortType {
  DATE_ASC = 'DATE_ASC',
  TITLE_ASC = 'TITLE_ASC',
  CMS_ASC = 'CMS_ASC',
  DATE_DESC = 'DATE_DESC',
  TITLE_DESC = 'TITLE_DESC',
  CMS_DESC = 'CMS_DESC',
}
