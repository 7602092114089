import { CategoryState } from '@/interfaces/states/category-state.interface';

const state: CategoryState = {
  items: [],
  loading: false,
  categoryFiles: [],
  error: {
    code: null,
    msg: null,
  },
  level: 0,
};

export default state;
