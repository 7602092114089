/* eslint-disable no-shadow */
// eslint-disable-next-line import/prefer-default-export
export enum LocalFileActions {
  FETCH = '[LocalFile] Fetch Highlights',
  CHANGE_DIR = '[LocalFile] Change Dir',
  LIST_DIR = '[LocalFile] List Dir',
  OPEN_FILE = '[LocalFile] Open File',
  SAVE_FILE = '[LocalFile] Save File',
  SET_LOADING = '[LocalFile] Set Loading',
  SET_ERROR = '[LocalFile] Set Error',
}
