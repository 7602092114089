
import Vue, { PropType } from 'vue';
import { extend, ValidationObserver, ValidationProvider } from 'vee-validate';
import { required, email } from 'vee-validate/dist/rules';
import { mapActions, mapGetters } from 'vuex';
import { AuthActions } from '@/store/auth/actions.enum';
import i18n from '@/i18n';

extend('required', {
  ...required,
  message: i18n.t('validate.required').toString(),
});
extend('email', {
  ...email,
  message: i18n.t('validate.email').toString(),
});

export default Vue.extend({
  name: 'ForgotPasswordDialog',

  components: {
    ValidationObserver,
    ValidationProvider,
  },

  data() {
    return {
      email: '',
      loading: false,
    };
  },

  props: {
    value: Boolean as PropType<boolean>,
  },

  computed: {
    ...mapGetters('auth', {
      initialEmail: 'username',
    }),

    show: {
      set(value: boolean) {
        this.$emit('input', value);
      },
      get(): boolean {
        return this.value;
      },
    },
  },

  created() {
    this.email = this.initialEmail;
  },

  methods: {
    ...mapActions('auth', {
      triggerForgot: AuthActions.FORGOT,
    }),

    hideDialog() {
      this.$emit('hide');
    },

    async resetPassword() {
      try {
        this.loading = true;
        await this.triggerForgot({ username: this.email });
        this.$emit('hide');
      } finally {
        this.loading = false;
      }
    },
  },
});
