import { File } from '@/interfaces/file.interface';
import { Thumbnail } from '@/interfaces/thumbnail.interface';

function getThumbnail(thumbnails: Thumbnail[], item: File) {
  return thumbnails.find(({ id }) => item.thumbnailId === id);
}

export default (items: File[], thumbnails: Thumbnail[]): File[] =>
  // eslint-disable-next-line implicit-arrow-linebreak
  items.map((item) => ({
    ...item,
    thumbnail: getThumbnail(thumbnails, item),
  }));
