import { File } from '@/interfaces/file.interface';
import { LocalFileState } from '@/interfaces/states/local-file-state.interface';
import { LocalFileActions } from './actions.enum';

export default {
  [LocalFileActions.LIST_DIR](state: LocalFileState, items: File[]): void {
    state.items = items;
    state.error = { code: null, msg: null };
    state.loading = false;
  },

  [LocalFileActions.OPEN_FILE](state: LocalFileState, currentUrl: string): void {
    state.currentUrl = currentUrl;
  },

  [LocalFileActions.SET_LOADING](state: LocalFileState, loading: boolean): void {
    state.loading = loading;
  },

  [LocalFileActions.SET_ERROR](state: LocalFileState, error: Error): void {
    state.error = {
      code: error.name ?? 'UNKNOWN',
      msg: error.message,
    };
    console.error(error);
  },
};
