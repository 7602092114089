import { LocaleState } from '@/interfaces/states/locale-state.interface';

const state: LocaleState = {
  items: [],
  currentId: null,
  error: {
    code: null,
    msg: null,
  },
};

export default state;
