import i18n from '@/i18n';
import { File } from '@/interfaces/file.interface';
import { SetStateError } from '@/interfaces/set-state-error.interface';
import { LocalFileState } from '@/interfaces/states/local-file-state.interface';
import { RootState } from '@/interfaces/states/root-state.interface';
import { NotificationActions } from '@/store/notification/actions.enum';
import createMimeTypeThumbnail from '@/utils/create-mime-type-thumbnail';
import fetchWithToken from '@/utils/fetch-with-token';
import { cleanThumbnails } from '@/utils/local-thumbnail-db';
import numberToDate from '@/utils/transform/number-to-date';
// eslint-disable-next-line import/order
import * as indexDb from 'idb-keyval';
// eslint-disable-next-line import/order
import { ActionContext } from 'vuex';
import { LocalFileActions } from './actions.enum';

const DIR_HANDLE_KEY = 'DIR_HANDLE';

let dirHandle: FileSystemDirectoryHandle | undefined;

export default {
  async [LocalFileActions.CHANGE_DIR]({
    dispatch,
  }: ActionContext<LocalFileState, RootState>): Promise<void> {
    dirHandle = await window.showDirectoryPicker();

    if (!dirHandle) {
      console.error('No folder handler was given!');
      return;
    }

    indexDb.set(DIR_HANDLE_KEY, dirHandle);

    dispatch(LocalFileActions.LIST_DIR);
  },

  async [LocalFileActions.LIST_DIR]({
    commit,
  }: ActionContext<LocalFileState, RootState>): Promise<void> {
    if (!dirHandle) {
      dirHandle = await indexDb.get(DIR_HANDLE_KEY);
    }

    if (!dirHandle) {
      commit(LocalFileActions.SET_LOADING, false);
      return;
    }

    commit(LocalFileActions.SET_LOADING, true);

    if ((await dirHandle.queryPermission()) !== 'granted') {
      // Request permission. If the user grants permission, return true.
      if ((await dirHandle.requestPermission()) !== 'granted') {
        return;
      }
    }

    let files: File[] = [];

    // eslint-disable-next-line no-restricted-syntax
    for await (const [id, entry] of dirHandle.entries()) {
      if (entry.kind !== 'file') {
        break;
      }

      const localFile = await entry.getFile();
      const lastDotIndex = localFile.name.lastIndexOf('.');
      const title = localFile.name.substring(0, lastDotIndex);

      // happens for hidden files
      // e.g. .DSStore
      if (!title) {
        // eslint-disable-next-line no-continue
        continue;
      }

      const extension = localFile.name.substring(lastDotIndex + 1).toLowerCase();

      const file: File = {
        id,
        title,
        extension,
        categoryIds: [],
        location: '',
        mimeType: localFile.type,
        size: localFile.size,
        isHighlight: false,
        metatags: [],
        localeIds: [],
        shareable: false,
        favorite: false,
        userOnly: false,
        orientation: '',
        created: numberToDate(localFile.lastModified),
        modified: numberToDate(localFile.lastModified),
        highlightPeriodEnd: '',
        highlightPeriodStart: '',
        publicationPeriodEnd: '',
        publicationPeriodStart: '',
        formFactor: '',
        thumbnailId: '',
        position: '',
        thumbnail: {
          id: localFile.name,
          size: localFile.size,
          modified: numberToDate(localFile.lastModified),
          location: await createMimeTypeThumbnail(extension),
        },
      };

      files = [...files, file];
    }

    await cleanThumbnails(files.map(({ id }) => id));

    commit(LocalFileActions.LIST_DIR, files);
  },

  async [LocalFileActions.OPEN_FILE](
    { commit }: ActionContext<LocalFileState, RootState>,
    id: string,
  ): Promise<{ localUrl: string | undefined; localFile: globalThis.File | undefined }> {
    if (!dirHandle) {
      dirHandle = await indexDb.get(DIR_HANDLE_KEY);
    }

    if (!dirHandle) {
      return { localUrl: undefined, localFile: undefined };
    }

    if ((await dirHandle.queryPermission()) !== 'granted') {
      // Request permission. If the user grants permission, return true.
      if ((await dirHandle.requestPermission()) !== 'granted') {
        return { localUrl: undefined, localFile: undefined };
      }
    }

    const fileHandle = await dirHandle.getFileHandle(id, { create: false });
    const localFile = await fileHandle.getFile();
    const localUrl = URL.createObjectURL(localFile);

    commit(LocalFileActions.OPEN_FILE, localUrl);

    return { localUrl, localFile };
  },

  async [LocalFileActions.SAVE_FILE](
    { dispatch, rootState }: ActionContext<LocalFileState, RootState>,
    item: File,
  ): Promise<void> {
    const ext = item?.extension ?? item?.location?.split('.')?.pop();
    const suggestedName = `${item.title}.${ext}`;

    const url = `${process.env.VUE_APP_BASE_URL}/${item.location}`.replaceAll('//files', '/files');

    const response = await fetchWithToken(rootState, url, dispatch);
    const fileBlob = await response.blob();

    // create a new handle
    const newHandle = await window.showSaveFilePicker({ suggestedName });

    // create a FileSystemWritableFileStream to write to
    const writableStream = await newHandle.createWritable();

    // write our file
    await writableStream.write(fileBlob);

    // close the file and write the contents to disk.
    await writableStream.close();
  },

  [LocalFileActions.SET_LOADING](
    { commit }: ActionContext<LocalFileState, RootState>,
    loading: boolean,
  ): void {
    commit(LocalFileActions.SET_LOADING, loading);
  },

  [LocalFileActions.SET_ERROR](
    { commit, dispatch }: ActionContext<LocalFileState, RootState>,
    { error, i18nKey }: SetStateError,
  ): void {
    commit(LocalFileActions.SET_ERROR, error);
    dispatch(`notification/${NotificationActions.SET_ERROR}`, i18n.t(i18nKey).toString(), {
      root: true,
    });
  },
};

export const clearDirHandle = (): void => {
  dirHandle = undefined;
};
