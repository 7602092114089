import { File } from '@/interfaces/file.interface';
import { FileState } from '@/interfaces/states/file-state.interface';
import { FileActions } from './actions.enum';

export default {
  [FileActions.FETCH_HIGHLIGHTS](state: FileState, items: File[]): void {
    state.highlights = items;
    state.showHighlights = items.length > 0;
    state.error = { code: null, msg: null };
    state.loading = false;
  },

  [FileActions.FETCH_LAST_UPLOADED](state: FileState, items: File[]): void {
    state.lastUploaded = items;
    state.error = { code: null, msg: null };
    state.loading = false;
  },

  [FileActions.SET_LOADING](state: FileState, loading: boolean): void {
    state.loading = loading;
  },

  [FileActions.SET_ERROR](state: FileState, error: Error & { response: Response }): void {
    state.error = {
      code: error?.response?.status.toString() ?? 0,
      msg: error.response?.statusText ?? error.message,
    };
    console.error(error);
  },
};
