import { Category } from '@/interfaces/category.interface';
import { File } from '@/interfaces/file.interface';
import { CategoryState } from '@/interfaces/states/category-state.interface';
import { CategoryActions } from './actions.enum';

export default {
  [CategoryActions.FETCH](state: CategoryState, items: Category[]): void {
    state.items = items;
    state.error = { code: null, msg: null };
  },

  [CategoryActions.FETCH_ITEMS](state: CategoryState, items: File[]): void {
    state.categoryFiles = items;
    state.error = { code: null, msg: null };
  },

  [CategoryActions.CLEAR_CATEGORY_FILES](state: CategoryState): void {
    state.categoryFiles = [];
  },

  [CategoryActions.SET_LOADING](state: CategoryState, loading: boolean): void {
    state.loading = loading;
  },

  [CategoryActions.SET_LEVEL](state: CategoryState, level: number): void {
    state.level = level;
  },

  [CategoryActions.SET_ERROR](state: CategoryState, error: Error & { response: Response }): void {
    state.error = {
      code: error?.response?.status.toString() ?? 0,
      msg: error.response?.statusText ?? error.message,
    };
    console.error(error);
  },
};
