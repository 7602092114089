
import Vue from 'vue';
import { ValidationObserver } from 'vee-validate';
import { mapActions, mapGetters } from 'vuex';
import SubmitButton from '@/components/BaseSubmitButton.vue';
import InputField from '@/components/BaseInputField.vue';
import ForgotPasswordDialog from '@/components/ForgotPasswordDialog.vue';
import { AuthActions } from '@/store/auth/actions.enum';
import { LocaleActions } from '@/store/locale/actions.enum';

export default Vue.extend({
  name: 'Login',

  components: {
    ValidationObserver,
    SubmitButton,
    InputField,
    ForgotPasswordDialog,
  },

  data() {
    return {
      username: '',
      password: '',
      loading: false,
      forgotPasswordVisible: false,
    };
  },

  computed: {
    ...mapGetters('auth', {
      initialUsername: 'username',
      initialPassword: 'password',
    }),
    ...mapGetters('locale', { locales: 'items', localeId: 'currentId' }),
    ...mapGetters('file', ['showHighlights']),

    logoPath() {
      return `${process.env.BASE_URL}img/logos/login.svg`;
    },

    isIE11() {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      return !!window.MSInputMethodContext && !!(document as any).documentMode;
    },
  },

  created() {
    const paramUsername = this.$route.query.username as string;
    const paramPassword = this.$route.query.password as string;

    this.username = paramUsername ?? this.initialUsername;
    this.password = paramPassword ?? this.initialPassword;

    if (paramUsername && paramPassword) {
      this.login();
    }

    if (this.$route.hash) {
      this.handleLegacyDeepLogin();
    }
  },

  methods: {
    ...mapActions('auth', { authWithCredentials: AuthActions.LOGIN }),
    ...mapActions('locale', {
      fetchLocale: LocaleActions.FETCH,
      setCurrentLocale: LocaleActions.SET_CURRENT,
    }),

    async login() {
      this.loading = true;

      try {
        await this.authWithCredentials({
          username: this.username,
          password: this.password,
        });
        await this.fetchLocale();
        await this.setCurrentLocale(this.localeId ?? this.locales?.[0]?.id);

        this.$router.push(this.showHighlights ? '/highlights' : '/categories');
      } finally {
        this.loading = false;
      }
    },

    showForgotPassword() {
      this.forgotPasswordVisible = true;
    },

    async handleLegacyDeepLogin() {
      const { hash } = this.$route;

      const regExp = new RegExp('#/login/deep/(.*)/(.*)');
      const matches = regExp.exec(hash);

      if (matches?.length === 3) {
        const [_, username, password] = matches;

        this.username = username;
        this.password = password;

        this.login();
      }
    },
  },
});
