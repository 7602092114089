import { LoginRequest } from '@/dtos/login-request.interface';
import { LoginResponse } from '@/dtos/login-response.interface';
import { AuthState } from '@/interfaces/states/auth-state.interface';
import { AuthActions } from './actions.enum';

export default {
  [AuthActions.LOGIN](
    state: AuthState,
    // eslint-disable-next-line object-curly-newline
    { token, username, password, userId }: LoginResponse & LoginRequest,
  ): void {
    state.token = token;
    state.username = username;
    state.password = password;
    state.userId = userId;
    state.error = { code: null, msg: null };
  },

  [AuthActions.SET_ERROR](state: AuthState, error: Error & { response: Response }): void {
    state.error = {
      code: error?.response?.status.toString() ?? 0,
      msg: error.response?.statusText ?? error.message,
    };
    state.token = null;
    console.error(error);
  },

  [AuthActions.LOGOUT](state: AuthState): void {
    state.token = null;
  },
};
